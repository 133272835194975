@font-face {
  font-family: opsly;
  src: url("opsly.66fb02fb.eot");
  src: url("opsly.66fb02fb.eot#iefix") format("embedded-opentype"), url("opsly.29775169.woff2") format("woff2"), url("opsly.c0b54728.woff") format("woff"), url("opsly.6110536b.ttf") format("truetype"), url("opsly.817f7346.svg#opsly") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  speak: never;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: .2em;
  margin-right: .2em;
  font-family: opsly;
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  display: inline-block;
}

.icon-search:before {
  content: "";
}

.icon-opsly:before {
  content: "";
}

.icon-python:before {
  content: "";
}

.icon-lambda:before {
  content: "";
}

.icon-java:before {
  content: "";
}

.icon-lock:before {
  content: "";
}

.icon-bin:before {
  content: "";
}

.icon-pen:before {
  content: "";
}

.icon-code-slash:before {
  content: "";
}

.icon-compute-resource:before {
  content: "";
}

.icon-buffer:before {
  content: "";
}

.icon-left-open:before {
  content: "";
}

.icon-down-open:before {
  content: "";
}

.icon-up-open:before {
  content: "";
}

.icon-down-dir:before {
  content: "";
}

.icon-up-dir:before {
  content: "";
}

.icon-left-dir:before {
  content: "";
}

.icon-right-dir:before {
  content: "";
}

.icon-folder:before {
  content: "";
}

.icon-folder-open:before {
  content: "";
}

.icon-tags:before {
  content: "";
}

.icon-bookmark:before {
  content: "";
}

.icon-alert:before {
  content: "";
}

.icon-ok:before {
  content: "";
}

.icon-mail:before {
  content: "";
}

.icon-tag:before {
  content: "";
}

.icon-view-split:before {
  content: "";
}

.icon-lambda-circle:before {
  content: "";
}

.icon-play:before {
  content: "";
}

.icon-pause:before {
  content: "";
}

.icon-stop:before {
  content: "";
}

.icon-to-end:before {
  content: "";
}

.icon-to-end-alt:before {
  content: "";
}

.icon-to-start:before {
  content: "";
}

.icon-to-start-alt:before {
  content: "";
}

.icon-fast-fw:before {
  content: "";
}

.icon-fast-bw:before {
  content: "";
}

.icon-eject:before {
  content: "";
}

.icon-check:before {
  content: "";
}

.icon-attach:before {
  content: "";
}

.icon-doc:before {
  content: "";
}

.icon-wrench:before {
  content: "";
}

.icon-globe:before {
  content: "";
}

.icon-cancel-outline:before {
  content: "";
}

.icon-pencil:before {
  content: "";
}

.icon-terminal:before {
  content: "";
}

.icon-clip:before {
  content: "";
}

.icon-accordion:before {
  content: "";
}

.icon-th-large:before {
  content: "";
}

.icon-th:before {
  content: "";
}

.icon-star:before {
  content: "";
}

.icon-star-empty:before {
  content: "";
}

.icon-star-half:before {
  content: "";
}

.icon-user:before {
  content: "";
}

.icon-users:before {
  content: "";
}

.icon-minus-circled:before {
  content: "";
}

.icon-info-circled:before {
  content: "";
}

.icon-lock-1:before {
  content: "";
}

.icon-lock-open:before {
  content: "";
}

.icon-comment:before {
  content: "";
}

.icon-chat:before {
  content: "";
}

.icon-print:before {
  content: "";
}

.icon-edit-1:before {
  content: "";
}

.icon-trash-empty:before {
  content: "";
}

.icon-credit-card:before {
  content: "";
}

.icon-floppy:before {
  content: "";
}

.icon-logs:before {
  content: "";
}

.icon-plus:before {
  content: "";
}

.icon-plus-1:before {
  content: "";
}

.icon-right-open:before {
  content: "";
}

.icon-minus:before {
  content: "";
}

.icon-minus-outline:before {
  content: "";
}

.icon-plus-outline:before {
  content: "";
}

.icon-minus-1:before {
  content: "";
}

.icon-balance:before {
  content: "";
}

.icon-opsly-cloud:before {
  content: "";
}

.icon-cancel:before {
  content: "";
}

.icon-cancel-1:before {
  content: "";
}

.icon-bell:before {
  content: "";
}

.icon-book:before {
  content: "";
}

.icon-folder-opsly:before {
  content: "";
}

.icon-info-opsly:before {
  content: "";
}

.icon-panel:before {
  content: "";
}

.icon-puzzle-1:before {
  content: "";
}

.icon-radio-checked:before {
  content: "";
}

.icon-stars:before {
  content: "";
}

.icon-toolbox:before {
  content: "";
}

.icon-x-ray:before {
  content: "";
}

.icon-rack:before {
  content: "";
}

.icon-block:before {
  content: "";
}

.icon-opsly-info-filled:before {
  content: "";
}

.icon-box-outline:before {
  content: "";
}

.icon-box-filled:before {
  content: "";
}

.icon-move-1:before {
  content: "";
}

.icon-move-2:before {
  content: "";
}

.icon-social:before {
  content: "";
}

.icon-workflow:before {
  content: "";
}

.icon-diagram:before {
  content: "";
}

.icon-diagram-chart:before {
  content: "";
}

.icon-clock:before {
  content: "";
}

.icon-download:before {
  content: "";
}

.icon-clock-1:before {
  content: "";
}

.icon-rocket:before {
  content: "";
}

.icon-bomb:before {
  content: "";
}

.icon-fire:before {
  content: "";
}

.icon-keys:before {
  content: "";
}

.icon-identity:before {
  content: "";
}

.icon-gateways:before {
  content: "";
}

.icon-dns:before {
  content: "";
}

.icon-network:before {
  content: "";
}

.icon-sql-2:before {
  content: "";
}

.icon-balancer:before {
  content: "";
}

.icon-auto-scaling:before {
  content: "";
}

.icon-aws-balancer-2:before {
  content: "";
}

.icon-full-screen:before {
  content: "";
}

.icon-exit-fullscreen:before {
  content: "";
}

.icon-move:before {
  content: "";
}

.icon-check-empty:before {
  content: "";
}

.icon-bookmark-empty:before {
  content: "";
}

.icon-docs:before {
  content: "";
}

.icon-mail-alt:before {
  content: "";
}

.icon-comment-empty:before {
  content: "";
}

.icon-chat-empty:before {
  content: "";
}

.icon-sitemap:before {
  content: "";
}

.icon-download-cloud:before {
  content: "";
}

.icon-upload-cloud:before {
  content: "";
}

.icon-stethoscope:before {
  content: "";
}

.icon-doc-text:before {
  content: "";
}

.icon-angle-left:before {
  content: "";
}

.icon-angle-right:before {
  content: "";
}

.icon-angle-up:before {
  content: "";
}

.icon-angle-down:before {
  content: "";
}

.icon-quote-left:before {
  content: "";
}

.icon-quote-right:before {
  content: "";
}

.icon-circle:before {
  content: "";
}

.icon-folder-empty:before {
  content: "";
}

.icon-folder-open-empty:before {
  content: "";
}

.icon-terminal-1:before {
  content: "";
}

.icon-code:before {
  content: "";
}

.icon-star-half-alt:before {
  content: "";
}

.icon-info:before {
  content: "";
}

.icon-puzzle:before {
  content: "";
}

.icon-lock-open-alt:before {
  content: "";
}

.icon-minus-squared:before {
  content: "";
}

.icon-ok-squared:before {
  content: "";
}

.icon-pencil-squared:before {
  content: "";
}

.icon-link-ext-alt:before {
  content: "";
}

.icon-euro:before {
  content: "";
}

.icon-pound:before {
  content: "";
}

.icon-dollar:before {
  content: "";
}

.icon-yen:before {
  content: "";
}

.icon-bitcoin:before {
  content: "";
}

.icon-doc-inv:before {
  content: "";
}

.icon-doc-text-inv:before {
  content: "";
}

.icon-female:before {
  content: "";
}

.icon-male:before {
  content: "";
}

.icon-wheelchair:before {
  content: "";
}

.icon-child:before {
  content: "";
}

.icon-cubes:before {
  content: "";
}

.icon-recycle:before {
  content: "";
}

.icon-file-pdf:before {
  content: "";
}

.icon-paper-plane:before {
  content: "";
}

.icon-circle-thin:before {
  content: "";
}

.icon-sliders:before {
  content: "";
}

.icon-plug:before {
  content: "";
}

.icon-trash:before {
  content: "";
}

.icon-toggle-off:before {
  content: "";
}

.icon-toggle-on:before {
  content: "";
}

.icon-user-secret:before {
  content: "";
}

.icon-user-plus:before {
  content: "";
}

.icon-user-times:before {
  content: "";
}

.icon-balance-scale:before {
  content: "";
}

@font-face {
  font-family: Inter;
  src: url("Inter-VariableFont_slnt,wght.4c50ba1d.ttf") format("truetype-variations");
  font-weight: 125 950;
  font-stretch: 75% 125%;
  font-style: normal;
}

.edp-container .edp-caret-button.edp-disabled, .edp-container .edp-caret.edp-disabled, .edp-container .edp-button.edp-disabled, .edp-container .edp-year-button.edp-disabled {
  color: #00000029;
  cursor: not-allowed;
}

.edp-container {
  width: 300px;
  background-color: #fff;
  display: block;
  position: absolute;
  overflow: hidden;
  box-shadow: 2px 3px 10px #0000004d;
}

.edp-container .edp-header-section {
  color: #fffc;
  background-color: #60b5cd;
  padding: 8px 24px 0;
}

.edp-container .edp-header-section .edp-header-active {
  color: #fff;
}

.edp-container .edp-header-section .edp-header-year {
  cursor: pointer;
  margin-bottom: 4px;
  padding: 10px;
  font-size: 14px;
  transition: box-shadow .25s;
  display: inline-block;
  box-shadow: 1px 1px 1px 1px #0000002e;
}

.edp-container .edp-header-section .edp-header-year.edp-header-active {
  font-weight: bolder;
  box-shadow: 0 0 #0000002e;
}

.edp-container .edp-month-wrapper {
  width: 300px;
  height: 240px;
  position: relative;
}

.edp-container .edp-month-day-wrapper {
  height: 48px;
  padding-bottom: 8px;
  position: relative;
  overflow: hidden;
}

.edp-container .edp-header-month-day {
  cursor: pointer;
  height: 40px;
  padding: 0 8px;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  transition: box-shadow .25s;
  animation-duration: .35s;
  animation-fill-mode: forwards;
  position: absolute;
}

.edp-container .edp-header-month-day.edp-month-day-previous {
  margin-right: 1px;
  animation-name: edp-slideupprevious;
}

.edp-container .edp-header-month-day:not(.edp-month-day-previous) {
  margin-right: 1px;
  animation-name: edp-slideupnext;
}

.edp-container .edp-header-month-day:not(.edp-header-active):not(.edp-month-day-previous) {
  box-shadow: 1px 1px 1px 1px #0000002e;
}

.edp-container .edp-month-slider {
  width: 300px;
  height: 240px;
  animation-duration: .35s;
  animation-fill-mode: forwards;
  position: absolute;
  top: 0;
  left: 0;
}

.edp-container .edp-month-slider.edp-in-next {
  animation-name: edp-slidenext;
}

.edp-container .edp-month-slider.edp-in-previous {
  animation-name: edp-slideprevious;
}

.edp-container .edp-month-slider.edp-out-next {
  animation-name: edp-slideoutnext;
}

.edp-container .edp-month-slider.edp-out-previous {
  animation-name: edp-slideoutprevious;
}

.edp-container .edp-caret-button {
  cursor: pointer;
  padding: 8px;
}

.edp-container .edp-caret {
  height: 12px;
  width: 12px;
  cursor: pointer;
  border-bottom: 2px solid #0000008a;
  border-left: 2px solid #0000008a;
  display: inline-block;
}

.edp-container .edp-caret.edp-disabled {
  opacity: .2;
}

.edp-container .edp-caret.edp-caret-left {
  transform: rotate(45deg);
}

.edp-container .edp-caret.edp-caret-right {
  transform: rotate(-135deg);
}

.edp-container .edp-month-change-section {
  color: #000000d6;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.edp-container .edp-column {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  display: inline-block;
}

.edp-container .edp-day-symbol {
  color: #0000008a;
  font-size: 12px;
}

.edp-container .edp-day-number {
  color: #000000db;
  cursor: pointer;
  background-color: #0000;
  border-radius: 50%;
  font-size: 12px;
  transition: background-color .3s;
}

.edp-container .edp-day-number:hover:not(.edp-empty-column):not(.edp-disabled-column):not(.edp-day-number-selected) {
  background-color: #00000014;
}

.edp-container .edp-day-number.edp-day-number-today {
  color: #60b5cd;
  font-weight: bolder;
}

.edp-container .edp-day-number.edp-day-number-selected {
  color: #fff;
  background-color: #60b5cd;
}

.edp-container .edp-empty-column {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  color: #fff0;
}

.edp-container .edp-disabled-column {
  color: #0000008a;
}

.edp-container .edp-body-section {
  background-color: #fff;
  padding-left: 8px;
  padding-right: 8px;
}

.edp-container .edp-bottom-section {
  justify-content: flex-end;
  padding-bottom: 16px;
  display: flex;
}

.edp-container .edp-button {
  color: #60b5cd;
  min-width: 64px;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-radius: 3px;
  outline: none;
  padding: 8px;
  font-weight: bold;
  line-height: 100%;
  transition: all .3s;
}

.edp-container .edp-button:not(:last-child) {
  margin-right: 16px;
}

.edp-container .edp-button:hover:not(.edp-disabled) {
  background-color: #00000014;
}

.edp-container .edp-button:focus {
  outline: none;
}

.edp-container .edp-year-button {
  color: #60b5cd;
  width: 200px;
  height: 40px;
  padding: 0;
  line-height: 40px;
  transition: all .3s;
  box-shadow: 0 0 #0000;
}

.edp-container .edp-year-button:hover {
  box-shadow: 0 1px 3px #00000029;
}

.edp-container .edp-year-button.edp-year-button-selected {
  height: 50px;
  font-size: 32px;
  line-height: 50px;
}

.edp-container .edp-year-picker {
  height: 320px;
  justify-content: center;
  display: flex;
  position: relative;
  overflow: auto;
}

@keyframes edp-slideupprevious {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-100%);
  }
}

@keyframes edp-slideupnext {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes edp-slidenext {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes edp-slideprevious {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes edp-slideoutnext {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes edp-slideoutprevious {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
}

/*# sourceMappingURL=index.282a35d5.css.map */
