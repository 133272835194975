@font-face {
  font-family: 'opsly';
  src: url('../font/opsly.eot?38577327');
  src: url('../font/opsly.eot?38577327#iefix') format('embedded-opentype'),
       url('../font/opsly.woff2?38577327') format('woff2'),
       url('../font/opsly.woff?38577327') format('woff'),
       url('../font/opsly.ttf?38577327') format('truetype'),
       url('../font/opsly.svg?38577327#opsly') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'opsly';
    src: url('../font/opsly.svg?38577327#opsly') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "opsly";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-search:before { content: '\e800'; } /* '' */
.icon-opsly:before { content: '\e801'; } /* '' */
.icon-python:before { content: '\e802'; } /* '' */
.icon-lambda:before { content: '\e803'; } /* '' */
.icon-java:before { content: '\e804'; } /* '' */
.icon-lock:before { content: '\e805'; } /* '' */
.icon-bin:before { content: '\e806'; } /* '' */
.icon-pen:before { content: '\e807'; } /* '' */
.icon-code-slash:before { content: '\e808'; } /* '' */
.icon-compute-resource:before { content: '\e809'; } /* '' */
.icon-buffer:before { content: '\e80a'; } /* '' */
.icon-left-open:before { content: '\e80b'; } /* '' */
.icon-down-open:before { content: '\e80c'; } /* '' */
.icon-up-open:before { content: '\e80d'; } /* '' */
.icon-down-dir:before { content: '\e80e'; } /* '' */
.icon-up-dir:before { content: '\e80f'; } /* '' */
.icon-left-dir:before { content: '\e810'; } /* '' */
.icon-right-dir:before { content: '\e811'; } /* '' */
.icon-folder:before { content: '\e812'; } /* '' */
.icon-folder-open:before { content: '\e813'; } /* '' */
.icon-tags:before { content: '\e814'; } /* '' */
.icon-bookmark:before { content: '\e815'; } /* '' */
.icon-alert:before { content: '\e816'; } /* '' */
.icon-ok:before { content: '\e817'; } /* '' */
.icon-mail:before { content: '\e818'; } /* '' */
.icon-tag:before { content: '\e819'; } /* '' */
.icon-view-split:before { content: '\e81a'; } /* '' */
.icon-lambda-circle:before { content: '\e81b'; } /* '' */
.icon-play:before { content: '\e81c'; } /* '' */
.icon-pause:before { content: '\e81d'; } /* '' */
.icon-stop:before { content: '\e81e'; } /* '' */
.icon-to-end:before { content: '\e81f'; } /* '' */
.icon-to-end-alt:before { content: '\e820'; } /* '' */
.icon-to-start:before { content: '\e821'; } /* '' */
.icon-to-start-alt:before { content: '\e822'; } /* '' */
.icon-fast-fw:before { content: '\e823'; } /* '' */
.icon-fast-bw:before { content: '\e824'; } /* '' */
.icon-eject:before { content: '\e825'; } /* '' */
.icon-check:before { content: '\e826'; } /* '' */
.icon-attach:before { content: '\e827'; } /* '' */
.icon-doc:before { content: '\e828'; } /* '' */
.icon-wrench:before { content: '\e829'; } /* '' */
.icon-globe:before { content: '\e82a'; } /* '' */
.icon-cancel-outline:before { content: '\e82b'; } /* '' */
.icon-pencil:before { content: '\e82c'; } /* '' */
.icon-terminal:before { content: '\e82d'; } /* '' */
.icon-clip:before { content: '\e82e'; } /* '' */
.icon-accordion:before { content: '\e82f'; } /* '' */
.icon-th-large:before { content: '\e830'; } /* '' */
.icon-th:before { content: '\e831'; } /* '' */
.icon-star:before { content: '\e832'; } /* '' */
.icon-star-empty:before { content: '\e833'; } /* '' */
.icon-star-half:before { content: '\e834'; } /* '' */
.icon-user:before { content: '\e835'; } /* '' */
.icon-users:before { content: '\e836'; } /* '' */
.icon-minus-circled:before { content: '\e837'; } /* '' */
.icon-info-circled:before { content: '\e838'; } /* '' */
.icon-lock-1:before { content: '\e839'; } /* '' */
.icon-lock-open:before { content: '\e83a'; } /* '' */
.icon-comment:before { content: '\e83b'; } /* '' */
.icon-chat:before { content: '\e83c'; } /* '' */
.icon-print:before { content: '\e83d'; } /* '' */
.icon-edit-1:before { content: '\e83e'; } /* '' */
.icon-trash-empty:before { content: '\e83f'; } /* '' */
.icon-credit-card:before { content: '\e840'; } /* '' */
.icon-floppy:before { content: '\e841'; } /* '' */
.icon-logs:before { content: '\e842'; } /* '' */
.icon-plus:before { content: '\e843'; } /* '' */
.icon-plus-1:before { content: '\e844'; } /* '' */
.icon-right-open:before { content: '\e845'; } /* '' */
.icon-minus:before { content: '\e846'; } /* '' */
.icon-minus-outline:before { content: '\e847'; } /* '' */
.icon-plus-outline:before { content: '\e848'; } /* '' */
.icon-minus-1:before { content: '\e849'; } /* '' */
.icon-balance:before { content: '\e84a'; } /* '' */
.icon-opsly-cloud:before { content: '\e84b'; } /* '' */
.icon-cancel:before { content: '\e84c'; } /* '' */
.icon-cancel-1:before { content: '\e84d'; } /* '' */
.icon-bell:before { content: '\e84e'; } /* '' */
.icon-book:before { content: '\e84f'; } /* '' */
.icon-folder-opsly:before { content: '\e850'; } /* '' */
.icon-info-opsly:before { content: '\e851'; } /* '' */
.icon-panel:before { content: '\e852'; } /* '' */
.icon-puzzle-1:before { content: '\e853'; } /* '' */
.icon-radio-checked:before { content: '\e854'; } /* '' */
.icon-stars:before { content: '\e855'; } /* '' */
.icon-toolbox:before { content: '\e856'; } /* '' */
.icon-x-ray:before { content: '\e857'; } /* '' */
.icon-rack:before { content: '\e858'; } /* '' */
.icon-block:before { content: '\e859'; } /* '' */
.icon-opsly-info-filled:before { content: '\e85a'; } /* '' */
.icon-box-outline:before { content: '\e85b'; } /* '' */
.icon-box-filled:before { content: '\e85c'; } /* '' */
.icon-move-1:before { content: '\e85d'; } /* '' */
.icon-move-2:before { content: '\e85e'; } /* '' */
.icon-social:before { content: '\e85f'; } /* '' */
.icon-workflow:before { content: '\e860'; } /* '' */
.icon-diagram:before { content: '\e861'; } /* '' */
.icon-diagram-chart:before { content: '\e862'; } /* '' */
.icon-clock:before { content: '\e863'; } /* '' */
.icon-download:before { content: '\e864'; } /* '' */
.icon-clock-1:before { content: '\e865'; } /* '' */
.icon-rocket:before { content: '\e866'; } /* '' */
.icon-bomb:before { content: '\e867'; } /* '' */
.icon-fire:before { content: '\e868'; } /* '' */
.icon-keys:before { content: '\e869'; } /* '' */
.icon-identity:before { content: '\e86a'; } /* '' */
.icon-gateways:before { content: '\e86b'; } /* '' */
.icon-dns:before { content: '\e86c'; } /* '' */
.icon-network:before { content: '\e86d'; } /* '' */
.icon-sql-2:before { content: '\e86e'; } /* '' */
.icon-balancer:before { content: '\e86f'; } /* '' */
.icon-auto-scaling:before { content: '\e870'; } /* '' */
.icon-aws-balancer-2:before { content: '\e871'; } /* '' */
.icon-full-screen:before { content: '\e872'; } /* '' */
.icon-exit-fullscreen:before { content: '\e873'; } /* '' */
.icon-move:before { content: '\f047'; } /* '' */
.icon-check-empty:before { content: '\f096'; } /* '' */
.icon-bookmark-empty:before { content: '\f097'; } /* '' */
.icon-docs:before { content: '\f0c5'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-comment-empty:before { content: '\f0e5'; } /* '' */
.icon-chat-empty:before { content: '\f0e6'; } /* '' */
.icon-sitemap:before { content: '\f0e8'; } /* '' */
.icon-download-cloud:before { content: '\f0ed'; } /* '' */
.icon-upload-cloud:before { content: '\f0ee'; } /* '' */
.icon-stethoscope:before { content: '\f0f1'; } /* '' */
.icon-doc-text:before { content: '\f0f6'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-quote-left:before { content: '\f10d'; } /* '' */
.icon-quote-right:before { content: '\f10e'; } /* '' */
.icon-circle:before { content: '\f111'; } /* '' */
.icon-folder-empty:before { content: '\f114'; } /* '' */
.icon-folder-open-empty:before { content: '\f115'; } /* '' */
.icon-terminal-1:before { content: '\f120'; } /* '' */
.icon-code:before { content: '\f121'; } /* '' */
.icon-star-half-alt:before { content: '\f123'; } /* '' */
.icon-info:before { content: '\f129'; } /* '' */
.icon-puzzle:before { content: '\f12e'; } /* '' */
.icon-lock-open-alt:before { content: '\f13e'; } /* '' */
.icon-minus-squared:before { content: '\f146'; } /* '' */
.icon-ok-squared:before { content: '\f14a'; } /* '' */
.icon-pencil-squared:before { content: '\f14b'; } /* '' */
.icon-link-ext-alt:before { content: '\f14c'; } /* '' */
.icon-euro:before { content: '\f153'; } /* '' */
.icon-pound:before { content: '\f154'; } /* '' */
.icon-dollar:before { content: '\f155'; } /* '' */
.icon-yen:before { content: '\f157'; } /* '' */
.icon-bitcoin:before { content: '\f15a'; } /* '' */
.icon-doc-inv:before { content: '\f15b'; } /* '' */
.icon-doc-text-inv:before { content: '\f15c'; } /* '' */
.icon-female:before { content: '\f182'; } /* '' */
.icon-male:before { content: '\f183'; } /* '' */
.icon-wheelchair:before { content: '\f193'; } /* '' */
.icon-child:before { content: '\f1ae'; } /* '' */
.icon-cubes:before { content: '\f1b3'; } /* '' */
.icon-recycle:before { content: '\f1b8'; } /* '' */
.icon-file-pdf:before { content: '\f1c1'; } /* '' */
.icon-paper-plane:before { content: '\f1d8'; } /* '' */
.icon-circle-thin:before { content: '\f1db'; } /* '' */
.icon-sliders:before { content: '\f1de'; } /* '' */
.icon-plug:before { content: '\f1e6'; } /* '' */
.icon-trash:before { content: '\f1f8'; } /* '' */
.icon-toggle-off:before { content: '\f204'; } /* '' */
.icon-toggle-on:before { content: '\f205'; } /* '' */
.icon-user-secret:before { content: '\f21b'; } /* '' */
.icon-user-plus:before { content: '\f234'; } /* '' */
.icon-user-times:before { content: '\f235'; } /* '' */
.icon-balance-scale:before { content: '\f24e'; } /* '' */
